import { FriendsList } from '@/Api/Message';
import Storage from "@/util/Storage"
import {FriendsListType} from "@/Api/Message/indexType";
import {findFriendSuc} from "@/Api/Friend";

// 获取 我的好友
export function getFriends(body:FriendsListType) {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    return Promise.resolve(FriendsList({ userId,token },body).then(res=>{
        return res.data;
    }))
}

// 查看好友
export function examineFriendMessage(phoneArr:string[]) {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    let phones = ""
    phoneArr && phoneArr.forEach((item,index)=>{
        if ( index ){
            phones += (","+item)
        }else{
            phones = item
        }
    })
    return Promise.resolve(findFriendSuc({ userId,token },{ phones }).then(res=>{
        return res.data;
    }))
}
