import BookPhoneType from './indexType';
import Pinyin from "@/util/Convert";
import { examineFriendMessage } from '../Server';
import Storage from "@/util/Storage"

// 过滤 请求数据
export async function filterList(list:BookPhoneType[] = [],myFriendList:any[] = []) {
    let keyData:any = {}
    if (list.length < 1) return keyData
    let pinyin = new Pinyin()
    list && list.forEach((item,index)=>{
        let key = myFriendList.findIndex(a=>a.phone == item.phone)
        if ( key < 0 ){
            let codeStr = item.name && pinyin.getCamelChars(item.name) || '#',
                code = codeStr.toLocaleUpperCase().slice(0,1);
            if (keyData[code]) {
                if (Array.isArray(keyData[code])) keyData[code].push(item)
            }else{
                keyData[code] = [item];
            }
        }
    })
    const keyArr = Object.keys(keyData);
    let ajaxList:any[] = [];
    let upNum = 0;
    // findFriendSuc
    keyArr && keyArr.forEach((item,index)=>{
        let phoneArr = keyData[item].map((item:any)=>item.phone)
        ajaxList.push({ [item]:examineFriendMessage(phoneArr) })
    })
    return new Promise((resolve, reject) => {
        ajaxList && ajaxList.forEach(((item,parentIndex)=>{
            let key = Object.keys(item)[0];
            item[key].then((res:any[])=>{
                upNum += 1;
                res.forEach(item=>{
                    let keyIndex = keyData[key].findIndex((a:any)=>a.phone == item.phone)
                    if ( keyIndex < 0 ){
                        keyData[key].push(item)
                    }else{
                        keyData[key][keyIndex] = { ...item,...keyData[keyIndex], }
                    }
                })
                if ( upNum >= keyArr.length ){
                    resolve({
                        keyList:keyArr,
                        keyData
                    })
                }
            })
        }))
    })
}

// 过滤 搜索数据
export function filterSearch(value = "",list:any[] = []) {
    const hookData = JSON.parse( Storage.GetData?.("hookData") || "{}" )
    let reg = new RegExp(value,"g");
    let key = list && list.findIndex(item=>item.phone && reg.test(item.phone + ''));
    let pinyin = new Pinyin()
    let keyData:any = {}
    if ( key >= 0 ){
        let codeStr = list[key]["name"] && pinyin.getCamelChars(list[key]["name"]),
            code = codeStr.toLocaleUpperCase().slice(0,1);
        if (hookData[code]) {
            let keyIndex = hookData[code].findIndex((a:any)=>a.phone == list[key]["phone"])
            if ( keyIndex < 0 ){
                keyData[code] = [ list[key] ]
            }else{
                keyData[code] = [ hookData[code][keyIndex] ]
            }
        }
    }
    return {
        keyList:Object.keys(keyData),
        keyData
    }
}
